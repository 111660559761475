footer {
    min-height: 35px;
    position: relative;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        .copyright {
            //font-size: 15px;
            color: #ad9b5a;
        }
        .mediaweb {
            //font-size: 15px;
            a {
                color: #ad9b5a;
                @extend .flex;
                @extend .items-center;
                img {
                    margin: -7px 0 0 0;
                }
                span { padding-left: 5px;}
                &:hover {
                    @extend .text-color-contrast;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            width: $size-footer-width-mobile;
        }
    }
    @media screen and (max-width: 1185px) {
        background: #000;
        min-height: 65px;
    }
}
