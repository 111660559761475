.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 75%;
        left: -5%;
        width: 100%;
        text-align: right;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            font-size: $font-size-46;
            font-weight: normal;
            color: $color-white;
            text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            font-size: $font-size-60;
            font-weight: 900;
            color: #f9dc20;
            text-transform: uppercase;
            text-shadow: 0 0 10px $color-black;
        }

    }
}
@media screen and (max-width: 1024px) {
    .module-header {
        &--caption {
            top: 70%;
            left: -2%;
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            top: 63%;
            left: -2%;
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            top: 88%;
            left: 2%;
            text-align: initial;
        }
    }
}
@media screen and (max-width: 360px) {
    .module-header {
        &--caption {
            &--title {
                font-size: 2rem;
            }
            &--subtitle {
                font-size: 2.5rem;
            }
        }
    }
}
