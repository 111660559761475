.btn {
    background-color: #f9dc20;
    color: $color-black;
    border: none;
    border-radius: 0;
    padding: 8px;
    font-weight: bold;
    font-size: $font-size-24;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-white;
    }
}
