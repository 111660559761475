.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: $size-nav-height + 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        width: 100%;
        @media screen and (max-width: $size-sm-max) {
          top: 15% !important;
          @media screen and (max-width: $size-xs-max) {
            top: 50% !important;
          }
        }
        h3 {
          font-weight: normal;
          font-size: $font-size-46;
          color: $color-white;
          text-align: right;
          text-shadow: 0 0 10px $color-black;
          @media screen and (max-width: $size-xs-max) {
              font-size: 2rem;
          }
        }
        h2 {
          font-weight: 900;
          font-size: $font-size-60;
          color: #f9dc20;
          text-transform: uppercase;
          text-align: right;
          text-shadow: 0 0 10px $color-black;
          @media screen and (max-width: $size-xs-max) {
              font-size: 2.7rem;
          }
        }
        .logo {
            padding-top: 150px;
            .img-responsive {
                margin: 0 0 0 auto;
            }
            @media screen and (max-width: 1550px) {
                padding-top: 65px;
                @media screen and (max-width: $size-md-max) {
                    padding-top: 35px;
                }
            }
        }
    }
    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
        		top: 0;
        		left: 0;
        		right: 0;
        		bottom: 0;
            width: 100vw;
        		overflow: hidden;
            opacity: 0;
        		position: absolute;
            //@include transition(right 1.5s ease-in-out);
        		backface-visibility: hidden;
            transition: 1.5s;

        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
        		&.transiting {
        			right: 0;
              left: 0;
              opacity: 1;
              //@include transition(right 1.5s ease-in-out);
              transition: 1.5s;
        		}
        		&.active {
        			right: 0;
              left: 0;
        			z-index: 10;
              opacity: 1;
              transition: 1.5s;
        			//@include transition(right 1.5s ease-in-out);
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
      @media screen and (max-width: $size-slider-breakpoint) {

          .caption {
              top: $size-nav-height-mobile + 20px;
          }
      }

}
