// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.owl-carousel .owl-item.active .owl-lazy {
    opacity: 1;
}

/** Replace the width of the container **/
.container {
    width: 1550px;
    margin: auto;
    @media screen and (max-width: 1550px) {
        width: 100%;
        padding: 0 25px;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-intro {
    background: url(../images/accueil_section01_bg.jpg) no-repeat;
    background-size: cover;
    padding: 100px 0;
    h3 {
        font-weight: 800;
        font-style: italic;
        font-size: $font-size-30;
        color: #3a3939;
        padding-bottom: 15px;
        text-align: center;
    }
    .border {
        padding-bottom: 50px;
        .img-responsive {
            margin: auto;
        }
    }
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #373737;
        margin: 0;
        text-align: justify;
        line-height: 1.5;
    }
    a.btn-intro {
        display: block;
        margin: 65px auto 0 auto;
        background: #f9dc20;
        max-width: 170px;
        text-align: center;
        padding: 15px 0;
        transition: 0.3s;
        h5 {
            font-weight: 600;
            font-size: $font-size-14;
            color: $color-black;
        }
        &:hover {
            background: #9e8409;
            transition: 0.3s;
        }
    }
}

#section-text-carousel {
    background: url(../images/accueil_section02_background.jpg) no-repeat;
    background-size: cover;
    padding: 100px 0;
    .left-side {
        margin-right: 8%;
        h3 {
            font-weight: 800;
            font-size: $font-size-30;
            color: #f9dc20;
            padding-bottom: 15px;
            text-align: center;
        }
        .border {
            padding-bottom: 50px;
            .img-responsive {
                margin: auto;
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #b2b1b1;
            margin: 0;
            text-align: justify;
            line-height: 1.5;
        }
        @media screen and (max-width: $size-xs-max) {
            margin-right: 0;
        }
    }
}

#section-burst {
    padding: 100px 0;
    background: #000;
}

#section-footer {
    background: url(../images/accueil_section04_bg.jpg) no-repeat;
    background-size: auto;
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: -50px;
    a.tel {
        margin-top: 50px;
        padding-right: 35px;
        border-right: 1px solid #000;
        .icon {
            position: relative;
            img {
                &:nth-child(1) {
                    opacity: 1;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    transition: 0.3s;
                }
            }
        }
        &:hover {
            h5 {
                color: #a78500;
                transition: 0.3s;
            }
            img {
                &:nth-child(1) {
                    opacity: 0;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    opacity: 1;
                    transition: 0.3s;
                }
            }
        }
    }
    a.fb {
        margin-top: 50px;
        padding-left: 35px;
        .icon {
            position: relative;
            img {
                &:nth-child(1) {
                    opacity: 1;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    transition: 0.3s;
                }
            }
        }
        &:hover {
            img {
                &:nth-child(1) {
                    opacity: 0;
                    transition: 0.3s;
                }
                &:nth-child(2) {
                    opacity: 1;
                    transition: 0.3s;
                }
            }
        }
    }
    .text {
        h5 {
            font-weight: 500;
            font-size: $font-size-18;
            color: $color-black;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: 1185px) {
        background: #ffcb08;
        margin-bottom: 0;
        height: 50px;
        a.tel, a.fb {
            margin-top: 0;
        }
        @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            height: 80px;
            a.tel {
                border-right: none;
                padding-bottom: 10px;
            }
        }
    }
}

/*******************************************************************************
* TRANSPORT HORS-NORMES
*******************************************************************************/

#section-intro.transport {
    min-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1450px) {
        min-height: initial;
        padding: 100px 50px;
        @media screen and (max-width: $size-xs-max) {
            padding: 100px 0;
        }
    }
}

#section-img-list-img {
    padding: 100px 0;
    background: url(../images/accueil_section02_background.jpg) no-repeat;
    background-size: cover;
    .left-side, .right-side {
        width: 27%;
    }
    .center-side {
        width: 47%;
        padding: 25px 50px 0 50px;
        h3 {
            font-weight: 800;
            font-size: $font-size-30;
            color: #f9dc20;
            padding-bottom: 15px;
            text-align: center;
        }
        .border {
            padding-bottom: 50px;
            .img-responsive {
                margin: auto;
            }
        }
        ul {
            list-style-image: url(../images/transport_section02_point_jaune.jpg);
            padding-top: 35px;
            margin-bottom: 0;
            padding-left: 35px;
            li {
                font-weight: 500;
                font-size: $font-size-22;
                color: $color-white;
                line-height: 1.3;
                padding-bottom: 20px;
                padding-left: 15px;
            }
            @media screen and (max-width: 1500px) {
                padding-top: 0;    
                li {
                    font-size: 1rem;
                }
            }
        }
        @media screen and (max-width: 1400px) {
            padding: 0 50px;
        }
    }
    @media screen and (max-width: 1024px) {
        .container {
            align-items: center;
        }
        @media screen and (max-width: $size-sm-max) {
            .container {
                flex-direction: column;
                .left-side, .right-side {
                    width: 50%;
                }
                .center-side {
                    padding: 50px 15%;
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side, .right-side {
                    width: 100% !important;
                }
                .center-side {
                    padding: 50px 0 !important;
                } 
            }
        }
    }
}

#section-list-img {
    background: #000;
    padding: 100px 0 0 0;
    .left-side {
        width: 55%;
        padding-right: 50px;
        h3 {
            font-weight: 800;
            font-size: $font-size-30;
            color: $color-white;
            padding-bottom: 15px;
            font-style: italic;
            text-align: center;
        }
        .border {
            padding-bottom: 50px;
            .img-responsive {
                margin: auto;
            }
        }
        ul {
            list-style-image: url(../images/transport_section02_point_jaune.jpg);
            padding-top: 15px;
            margin-bottom: 0;
            padding-left: 35px;
            li {
                font-weight: 500;
                font-size: $font-size-22;
                color: $color-white;
                line-height: 1.3;
                padding-bottom: 20px;
                padding-left: 15px;
            }
        }
    }
    .right-side {
        width: 45%;
        padding-top: 10%;
    }
    @media screen and (max-width: $size-sm-max) {
        .container {
             flex-direction: column;
             .left-side {
                width: 100%;
                padding: 0;
            }
            .right-side {
                width: 100%;
            }
        }
    }
}

/*******************************************************************************
* LOGISTIQUE
*******************************************************************************/





/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#section-intro.soumission {
    background-size: initial;
    p {
        text-align: center;
    }
    .module-formulaire {
        form .form-group {
            position: relative;
            .buttons.flex-auto {
                position: absolute;
                left: 29.3%;
            }
        }
        .section-btn {
            justify-content: right;
        }
        @media screen and (max-width: 1550px) {
            label.mx8 {
                margin: 0.5rem 1rem;
            }
            label.m4, label.mx8 {
                font-size: 1rem;
            }
            @media screen and (max-width: 1415px) {
                .form-group.col-xs-12.col-sm-7.col-sm-push-2.flex.items-center {
                    flex-direction: column;
                    align-items: baseline;
                }
                .flex.flex-wrap.form-group.list-checkBox.py8 {
                    padding: 15px 0;
                }
                .buttons.flex-auto {
                    position: initial !important;
                }
                @media screen and (max-width: 1024px) {
                    .form-group.col-xs-12.col-sm-7.col-sm-push-2 {
                        left: 10%;
                        width: 75%;
                    }
                     @media screen and (max-width: $size-sm-max) {
                        .form-group.col-xs-12.col-sm-7.col-sm-push-2 {
                            left: 5%;
                            width: 85%;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .form-group.col-xs-12.col-sm-7.col-sm-push-2 {
                                left: 0;
                                width: 100%;
                            }
                            .flex-auto {
                                width: 100%;
                                justify-content: center;
                                margin-bottom: 15px;
                            }
                            label.m4, label.mx8 {
                                font-size: 1.2rem;
                                margin-left: 0;
                            }
                            form input[type="checkbox"] {
                                position: absolute;
                                right: -40px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-intro.emplois {
    .section-emplois {
        section.emplois-liste {
            .item-poste.emploi {
                padding: 0 50px 75px 50px;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-24;
                    color: #373737;
                    padding-bottom: 10px;
                }
                p {
                    font-weight: 600;
                    font-size: $font-size-20;
                    color: #373737;
                    line-height: 1.5;
                    margin: 0;
                }
                ul {
                    padding-top: 15px;
                    li {
                        font-weight: 600;
                        font-size: $font-size-20;
                        color: #373737;
                        line-height: 1.5;
                        padding-bottom: 5px;
                        text-transform: initial;
                    }
                }
                .btn {
                    width: 200px;
                    justify-content: center;
                    text-transform: uppercase;
                    display: flex;
                    margin: auto;
                }
                @media screen and (max-width: 1024px) {
                    padding: 0 0 75px 0;
                    .btn {
                        margin: 25px auto 0 auto;
                    }
                }
            }
        }
    }
}

section#section-form {
    background: #000;
    padding: 100px 0 35px 0;
    h3 {
        font-weight: 800;
        font-size: $font-size-30;
        color: $color-white;
        padding-bottom: 15px;
        font-style: italic;
        text-align: center;
    }
    .border {
        padding-bottom: 50px;
        .img-responsive {
            margin: auto;
        }
    }
    div.label-wrapper {
        width: 200px;
    }
    label {
        font-size: $font-size-24;
        color: #fff;
    }
    input.form-control {
        height: 40px;
        border: none;
        background: #fff;
        border-radius: 0;
    }
    input#poste {
        background: #f9dc20;
    }
    label.btn.filedoc {
        font-weight: bold;
        background: #fff;
        color: #000;
        text-transform: uppercase;
        &:hover {
            background-color: #f9dc20;
            color: $color-black;
        }
    }
    @media screen and (max-width: 1298px) {
        form .buttons {
            margin-left: 35px;
        }
        .btn-submit {
            min-width: 310px !important;
        }
        @media screen and (max-width: 1024px) {
            .form-group.col-xs-12.col-sm-7.col-sm-push-2 {
                left: 10%;
                width: 75%;
            }
            @media screen and (max-width: $size-sm-max) {
                .form-group.col-xs-12.col-sm-7.col-sm-push-2 {
                    left: 5%;
                    width: 85%;
                }
                .buttons {
                    margin-left: 68px !important;
                }
                @media screen and (max-width: $size-xs-max) {
                    .form-group.col-xs-12.col-sm-7.col-sm-push-2 {
                        left: 0;
                        width: 100%;
                    }
                    .flex-auto {
                        width: 100%;
                    }
                    .buttons {
                        margin-left: 0 !important;
                    }
                    .btn-submit, #fp {
                        min-width: 250px !important;
                        margin: 15px auto 5px auto;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-intro.coordonnees {
    .border {
        padding: 10px 0 35px 0;
    }
    .info {
        width: 860px;
        margin: auto;
        .icon {
            padding-right: 35px;
        }
        .icon-text.last {
            h3 {
                text-align: initial;
                padding-bottom: 5px;
            }
            .title {
                margin-right: 50px;
            }
        }
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
        @media screen and (max-width: $size-xs-max) {
            .icon-text {
                flex-direction: column;
                .icon {
                    padding-right: 0;
                    padding-bottom: 25px;
                    .img-responsive {
                        margin: auto;
                    }
                }
            }
            .icon-text.last h3 {
                font-size: 1.3rem;
                @media screen and (max-width: 360px) {
                    font-size: 1rem;
                }
            }
        }
    }
    .section-map {
        padding: 35px 0;
        div#content {
            h4#firstHeading {
                font-size: 20px;
                font-weight: 500;
            }
            a.map_link {
                color: #373737;
                font-size: 16px;
            }
        }
    }
    .section-personnes {
        padding-left: 3%;
        .bloc {
            width: 50%;
            padding-bottom: 50px;
            h4, h5 {
                font-weight: 500;
                font-size: $font-size-24;
                color: #373737;
            }
            h5 {
                font-style: italic;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            padding-left: 0;
            padding-top: 50px;
            .bloc {
                width: 100%;
                text-align: center;
            }
        }   
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 75px 0 50px 0;
    }
}




/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
  

}
