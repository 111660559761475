.bursts {
    width: 100%;
    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 1.25rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .border {
                    border: 1px solid $color-white;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                }
                .icon {
                    padding-bottom: 15px;
                    img.img-responsive {
                        margin: auto;
                    }
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3,h4 {
                color: $color-black;
                text-align: center;
                transition: all 0.2s ease-out; // OUT effect
            }
            h3 {
                font-size: $font-size-26;
                font-weight: 600;
                text-transform: none;
            }
            h4 {
                font-size: $font-size-26;
                font-weight: normal;
                padding-bottom: 5px;
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
            }
        }
    }
}
@media screen and (max-width: 1520px) {
    .bursts {
        .bursts-container {
            .burst {
                h3,h4 {
                    font-size: 1.15rem;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .bursts {
            .bursts-container {
                .burst {
                    h3,h4 {
                        font-size: 0.85rem;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $size-sm-max) {
        .bursts {
            .bursts-container {
                .burst {
                    h3,h4 {
                        font-size: 0.7rem;
                    }
                }
                .icon {
                    padding-bottom: 0 !important;
                    width: 30%;
                }
            }
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .bursts {
            .bursts-container {
                flex-direction: column;
                a.px5 {
                    padding: 1rem 0;
                }
                .burst {
                    h3, h4 {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}